define("lh-web/routes/employees/index", ["exports", "lh-web/routes/current-user-route"], function (_exports, _currentUserRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _currentUserRoute.default.extend({
    model: function model() {
      var _this = this;

      return new Ember.RSVP.hash({
        employeesPage: this.get("prismic").getApi().then(function (api) {
          return api.query(PrismicJS.Predicates.at("document.type", "employees-page"), {
            lang: _this.get("currentUser.language")
          });
        }),
        employees: this.get("prismic").getApi().then(function (api) {
          return api.query(PrismicJS.Predicates.at("document.type", "employee"), {
            lang: _this.get("currentUser.language")
          });
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.set("page", this.get("dataFormatter").formatPage(model.employeesPage.results));
      controller.set("employees", this.get("dataFormatter").formatEmployees(model.employees.results));
    },
    actions: {
      didTransition: function didTransition() {
        window.scrollTo(0, 0);
      }
    }
  });

  _exports.default = _default;
});