define("lh-web/templates/components/employee-feature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G/lC2NH4",
    "block": "{\"symbols\":[],\"statements\":[[9,\"img\",true],[12,\"class\",\"portrait\",null],[13,\"src\",[27,[26,1,\"AppendSingleId\"],[]],null],[13,\"alt\",[32,[[27,[26,2,\"AppendSingleId\"],[]]]],null],[10],[11],[1,1,0,0,\"\\n\"],[9,\"div\",true],[12,\"class\",\"info\",null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[27,[26,2,\"AppendSingleId\"],[]]],[9,\"br\",true],[10],[11],[1,1,0,0,\"  \\n  \"],[1,0,0,0,[27,[26,3,\"AppendSingleId\"],[]]],[9,\"br\",true],[10],[11],[1,1,0,0,\"\\n  \"],[9,\"a\",true],[13,\"href\",[32,[\"mailto:\",[27,[26,4,\"AppendSingleId\"],[]]]],null],[10],[1,0,0,0,[27,[26,4,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,6,\"BlockHead\"],[]],null,[[\"class\",\"tagName\",\"route\",\"model\"],[\"btn\",\"button\",\"employees.employee\",[27,[26,5,\"Expression\"],[]]]],[[\"default\"],[{\"statements\":[[9,\"span\",true],[12,\"class\",\"light\",null],[10],[1,0,0,0,[31,336,1,[27,[26,0,\"CallHead\"],[]],[\"um\"],null]],[11],[1,1,0,0,\" \"],[9,\"strong\",true],[10],[1,0,0,0,[31,362,1,[27,[26,0,\"CallHead\"],[]],[\"starfsmanninn\"],null]],[11]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"photo\",\"name\",\"occupation\",\"email\",\"modelId\",\"link-to\"]}",
    "meta": {
      "moduleName": "lh-web/templates/components/employee-feature.hbs"
    }
  });

  _exports.default = _default;
});