define("lh-web/routes/employees/employee", ["exports", "lh-web/routes/current-user-route"], function (_exports, _currentUserRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _currentUserRoute.default.extend({
    model: function model(params) {
      var _this = this;

      return this.get("prismic").getApi().then(function (api) {
        return api.getByUID("employee", params.id, {
          lang: _this.get("currentUser.language"),
          fetchLinks: ["field.title", "field.description"]
        });
      });
    },
    setupController: function setupController(controller, model) {
      if (!model) {
        return;
      }

      controller.set("model", this.get("dataFormatter").formatEmployee(model));
    },
    actions: {
      didTransition: function didTransition() {
        window.scrollTo(0, 0);
      }
    }
  });

  _exports.default = _default;
});