define("lh-web/routes/fields/index", ["exports", "lh-web/routes/current-user-route"], function (_exports, _currentUserRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _currentUserRoute.default.extend({
    model: function model() {
      var _this = this;

      return new Ember.RSVP.hash({
        fieldsPage: this.get('prismic').getApi().then(function (api) {
          return api.query(PrismicJS.Predicates.at('document.type', 'fields-page'), {
            lang: _this.get('currentUser.language')
          });
        }),
        fields: this.get('prismic').getApi().then(function (api) {
          return api.query(PrismicJS.Predicates.at('document.type', 'field'), {
            "pageSize": 100,
            lang: _this.get('currentUser.language')
          });
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('fieldsPage', this.get('dataFormatter').formatPage(model.fieldsPage.results));
      controller.set('fields', this.get('dataFormatter').formatFields(model.fields.results));
    },
    actions: {
      didTransition: function didTransition() {
        window.scrollTo(0, 0);
      }
    }
  });

  _exports.default = _default;
});