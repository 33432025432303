define("lh-web/routes/information", ["exports", "lh-web/routes/current-user-route"], function (_exports, _currentUserRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _currentUserRoute.default.extend({
    model: function model() {
      var _this = this;

      return this.get('prismic').getApi().then(function (api) {
        return api.query(PrismicJS.Predicates.at('document.type', 'information-page'), {
          lang: _this.get('currentUser.language')
        });
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model.results.length ? model.results[0] : null);
    },
    actions: {
      didTransition: function didTransition() {
        window.scrollTo(0, 0);
      }
    }
  });

  _exports.default = _default;
});