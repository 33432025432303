define("lh-web/routes/current-user-route", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: (0, _emberLocalStorage.storageFor)('current-user')
  });

  _exports.default = _default;
});