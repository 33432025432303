define("lh-web/storages/current-user", ["exports", "ember-local-storage/local/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Storage = _object.default.extend();

  Storage.reopenClass({
    initialState: function initialState() {
      return {
        language: "".concat(this.language ? this.language : "en-us")
      };
    },
    recoverState: function recoverState() {
      return this.initialState();
    }
  });
  var _default = Storage;
  _exports.default = _default;
});