define("lh-web/components/x-employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['employees'],
    orderedEmployees: Ember.computed('employees.@each.order', function () {
      // this.employees.forEach(employee => {
      //   let words = employee.name.split(' ')
      //   let temp = '';
      //   for(let i = 0; i < words.length; ++i) {
      //      temp += words[i].charAt(0);
      //   }
      //   employee.initials = temp;
      // });
      return this.employees.sort(function (a, b) {
        return a.order - b.order;
      });
    }),
    getRandomColor: function getRandomColor() {
      var rgbArr = ["rgb(83, 173, 229)", "rgba(40, 105, 143, 0.8)"];
      var index = Math.floor(Math.random() * 3);
      var color = rgbArr[index];
      return color || null;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
      $(".avatar span").each(function (i, elem) {
        var color = self.getRandomColor();

        if (color) {
          $(elem).css('background-color', color);
        }
      });
    }
  });

  _exports.default = _default;
});