define("lh-web/routes/application", ["exports", "lh-web/mixins/loading-slider", "ember-local-storage"], function (_exports, _loadingSlider, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_loadingSlider.default, {
    currentUser: (0, _emberLocalStorage.storageFor)("current-user"),
    intl: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.get("currentUser.language")) {
        return this.intl.setLocale([this.get("currentUser.language")]);
      }

      return this.intl.setLocale(["en-us"]);
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      if (!this.get("currentUser") || !this.get("currentUser.language")) {
        this.set("currentUser.language", "en-us");
      }
    },
    actions: {
      willTransition: function willTransition() {
        $("html,body").removeClass("no-scroll");
      }
    }
  });

  _exports.default = _default;
});