define("lh-web/templates/company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kElSGERf",
    "block": "{\"symbols\":[\"history\"],\"statements\":[[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[9,\"div\",true],[12,\"class\",\"content company\",null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,44,12,[27,[26,3,\"CallHead\"],[]],null,[[\"class\",\"mainCompany\",\"title\",\"description\"],[\"nav-margin company-bg\",true,[31,111,1,[27,[26,2,\"CallHead\"],[]],[\"Fyrirtækið\"],null],[27,[26,1,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"container container-padded\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"company-content\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"h2\",true],[10],[1,0,0,0,[31,242,1,[27,[26,2,\"CallHead\"],[]],[\"Sagan\"],null]],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,6,\"BlockHead\"],[]],[[31,0,0,[27,[26,5,\"CallHead\"],[]],[[31,0,0,[27,[26,5,\"CallHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[9,\"p\",true],[10],[1,1,0,0,\"\\n        \"],[1,0,0,0,[27,[24,1],[\"text\"]]],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"x-nav\",\"description\",\"t\",\"highlight-bg\",\"history\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "lh-web/templates/company.hbs"
    }
  });

  _exports.default = _default;
});