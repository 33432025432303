define("lh-web/templates/components/job-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hNcOY0k1",
    "block": "{\"symbols\":[],\"statements\":[[9,\"strong\",true],[10],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"],[9,\"hr\",true],[10],[11],[1,1,0,0,\"\\n\"],[9,\"p\",true],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[27,[26,1,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"description\"]}",
    "meta": {
      "moduleName": "lh-web/templates/components/job-field.hbs"
    }
  });

  _exports.default = _default;
});