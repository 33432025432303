define("lh-web/services/mobile-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    adaptToOrientation: function adaptToOrientation() {
      var noChangeCountToEnd = 100,
          noEndTimeout = 1000;
      window.addEventListener('orientationchange', function () {
        var interval, timeout, end, lastInnerWidth, lastInnerHeight, noChangeCount;

        end = function end() {
          clearInterval(interval);
          clearTimeout(timeout);
          interval = null;
          timeout = null;
          $('#hero').height(window.innerHeight - 109);
        };

        interval = setInterval(function () {
          if (global.innerWidth === lastInnerWidth && global.innerHeight === lastInnerHeight) {
            noChangeCount++;

            if (noChangeCount === noChangeCountToEnd) {
              end();
            }
          } else {
            lastInnerWidth = global.innerWidth;
            lastInnerHeight = global.innerHeight;
            noChangeCount = 0;
          }
        });
        timeout = setTimeout(function () {
          end();
        }, noEndTimeout);
      });
    },
    getUA: function getUA() {
      var ua = navigator.userAgent.toLowerCase();
      var isAndroid = ua.indexOf("android") > -1;
      var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      if (isAndroid) {
        return 'android';
      }

      if (iOS) {
        return 'ios';
      }
    },
    isMobile: function isMobile() {
      return this.getUA() == 'android' || this.getUA() == 'ios';
    },
    adaptToUA: function adaptToUA() {
      var _this = this;

      setTimeout(function () {
        if (_this.getUA() == 'android') {
          $('.apple').hide();
        }

        if (_this.getUA() == 'ios') {
          $('.android').hide();
        }
      }, 200);
    }
  });

  _exports.default = _default;
});