define("lh-web/components/number-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    className: Ember.computed('class', 'isLoading', 'isDisabled', function () {
      var className = this.get('class');
      if (this.get('isLoading')) className += ' loading';
      if (this.get('disabled')) className += ' disabled';
      return className;
    }),
    actions: {
      handleKeyDown: function handleKeyDown(e) {
        var key = e.charCode || e.keyCode || 0;
        var char = String.fromCharCode(key); // allow cmd+a

        if (e.metaKey && key == 65) {
          return true;
        } // allow plus for phone numbers


        if (this.get('isMsisdn') && key == 187) {
          return true;
        }

        if (isNaN(char) && key != 8 && key != 46 && key != 37 && key != 39 && key != 9 && key != 16 && !(e.keyCode >= 96 && e.keyCode <= 105) && key != 107) {
          return false;
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});