define("lh-web/components/contact-form", ["exports", "lh-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var environment = _environment.default.environment,
      LHEMAIL = _environment.default.LHEMAIL;

  var _default = Ember.Component.extend({
    emailService: Ember.inject.service(),
    classNames: ['contact-form'],
    classNameBindings: ['padding:with-padding'],
    actions: {
      sendEmail: function sendEmail() {
        var _this = this;

        var name = this.get('name');
        var email = this.get('email');
        var msisdn = this.get('phone');
        var content = this.get('msg');
        var to = this.get('employeeAddress') && environment === 'production' ? this.get('employeeAddress') : LHEMAIL;
        var toName = this.get('employeeName') ? this.get('employeeName') : 'Magna lögmenn';
        this.set('isLoading', true);
        this.get('emailService').sendEmail(name, email, to, toName, msisdn, content).then(function () {
          _this.get('notifications').success('Skilaboðin þín voru send. Takk fyrir.');

          _this.set('name');

          _this.set('email');

          _this.set('phone');

          _this.set('msg');
        }).catch(function (err) {
          console.log(err);

          _this.get('notifications').error("Ekki t\xF3kst a\xF0 senda skilabo\xF0. Vinsamlegast haf\xF0u samband \xE1 ".concat(LHEMAIL));
        }).finally(function () {
          _this.set('isLoading');
        });
      }
    }
  });

  _exports.default = _default;
});