define("lh-web/components/x-nav", ["exports", "lh-web/config/environment", "ember-local-storage"], function (_exports, _environment, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var modulePrefix = _environment.default.modulePrefix;

  var _default = Ember.Component.extend({
    classNames: ['x-nav'],
    mobileUtils: Ember.inject.service(),
    currentUser: (0, _emberLocalStorage.storageFor)('current-user'),
    // activeLocale: computed.readOnly('intl.locale'),
    //
    // locales: computed(function() {
    //   return lookupByFactoryType('translations', modulePrefix).map((moduleName) => moduleName.split('/').pop());
    // }).readOnly(),
    //
    // selections: computed('locales.[]', 'activeLocale', function() {
    //   let active = get(this, 'activeLocale');
    //   return get(this, 'locales').map(locale => {
    //     return {
    //       locale: locale,
    //       active: active.indexOf(locale) > -1,
    //       alias: locale.indexOf('en') > -1 ? "EN" : "ISL"
    //     };
    //   });
    // }).readOnly(),
    toggleMenu: function toggleMenu() {
      this.toggleProperty('mobileMenu');

      if (!this.get('mobileMenu')) {
        setTimeout(function () {
          $('#menu').removeClass('animated show slideOutLeft');
        }, 500);
        $('#menu').removeClass('slideInLeft').addClass('slideOutLeft');
        $('html, body').removeClass('no-scroll');
      } else {
        $('html, body').addClass('no-scroll');
        $('#menu').addClass('animated show slideInLeft');
        window.scrollTo(0, 0);
      }
    }
  });

  _exports.default = _default;
});