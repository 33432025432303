define("lh-web/controllers/company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    filter: 'history',
    description: Ember.computed('model.data.content', function () {
      return this.get('model.data.content.firstObject.text');
    }),
    history: Ember.computed('model.data.history', function () {
      return this.get('model.data.history');
    })
  });

  _exports.default = _default;
});