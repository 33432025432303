define("lh-web/templates/components/x-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BDg6bQtv",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"container\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"ul\",true],[10],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[10],[1,1,0,0,\"\\n      MAGNA lögmenn\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[10],[1,1,0,0,\"\\n      Höfðabakki 9\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[10],[1,1,0,0,\"\\n      110 Reykjavík\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[10],[1,1,0,0,\"\\n      \"],[1,0,0,0,[31,164,1,[27,[26,0,\"CallHead\"],[]],[\"kt.\"],null]],[1,1,0,0,\" 541268-0149\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[10],[1,1,0,0,\"\\n      \"],[9,\"a\",true],[12,\"href\",\"mailto:logmenn@magna.is\",null],[10],[1,1,0,0,\"logmenn@magna.is\"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[10],[1,1,0,0,\"\\n      571 5400\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"a\",true],[12,\"href\",\"https://www.facebook.com/MAGNA-Lögmenn-274654759348935/\",null],[12,\"target\",\"_blank\",null],[12,\"class\",\"facebook\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"span\",true],[12,\"class\",\"facebook\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"img\",true],[12,\"src\",\"../images/icons/facebook.png\",null],[12,\"alt\",\"facebook\",null],[12,\"class\",\"facebook\",null],[10],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "lh-web/templates/components/x-footer.hbs"
    }
  });

  _exports.default = _default;
});