define("lh-web/routes/contact", ["exports", "lh-web/routes/current-user-route"], function (_exports, _currentUserRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _currentUserRoute.default.extend({
    actions: {
      didTransition: function didTransition() {
        window.scrollTo(0, 0);
      }
    }
  });

  _exports.default = _default;
});