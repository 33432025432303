define("lh-web/services/loading-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    startLoading: function startLoading() {
      this.trigger('startLoading');
    },
    endLoading: function endLoading() {
      this.trigger('endLoading');
    },
    changeAttrs: function changeAttrs(attrs) {
      this.trigger('changeAttrs', attrs);
    }
  });

  _exports.default = _default;
});