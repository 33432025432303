define("lh-web/templates/fields/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zIqmNE8n",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[9,\"div\",true],[12,\"class\",\"content\",null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,36,12,[27,[26,3,\"CallHead\"],[]],null,[[\"class\",\"mainJobField\",\"title\",\"description\"],[\"nav-margin\",true,[31,93,1,[27,[26,2,\"CallHead\"],[]],[\"Starfssvið\"],null],[27,[26,1,\"Expression\"],[\"content\"]]]]]],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,146,10,[27,[26,5,\"CallHead\"],[]],null,[[\"id\",\"fields\"],[\"subpage-fields\",[27,[26,4,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"x-nav\",\"fieldsPage\",\"t\",\"highlight-bg\",\"fields\",\"job-fields\"]}",
    "meta": {
      "moduleName": "lh-web/templates/fields/index.hbs"
    }
  });

  _exports.default = _default;
});