define("lh-web/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VeG1Ui+C",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[31,2,6,[27,[26,0,\"CallHead\"],[]],null,[[\"id\"],[\"hero\"]]]],[1,1,0,0,\"\\n\"],[1,0,0,0,[31,23,10,[27,[26,2,\"CallHead\"],[]],null,[[\"id\",\"class\",\"showMore\",\"fields\"],[\"index-fields\",\"test\",true,[27,[26,1,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"],[1,0,0,0,[31,97,11,[27,[26,5,\"CallHead\"],[]],null,[[\"title\",\"employees\"],[[31,116,1,[27,[26,4,\"CallHead\"],[]],[\"Starfsfólk MAGNA Lögmanna\"],null],[27,[26,3,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"x-hero\",\"fields\",\"job-fields\",\"employees\",\"t\",\"x-employees\"]}",
    "meta": {
      "moduleName": "lh-web/templates/index.hbs"
    }
  });

  _exports.default = _default;
});