define("lh-web/components/action-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNameBindings: ['isLoading', 'disabled:disabled-loading:enabled'],
    isLoading: false,
    disabled: false,
    click: function click() {
      this.get('action')();
    }
  });

  _exports.default = _default;
});