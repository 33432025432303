define("lh-web/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "daBoegNX",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[31,2,14,[27,[26,1,\"CallHead\"],[]],null,[[\"isLoading\",\"expanding\",\"color\"],[[27,[26,0,\"Expression\"],[]],true,\"#ffffff\"]]]],[1,1,0,0,\"\\n\"],[1,0,0,0,[31,70,22,[27,[26,2,\"CallHead\"],[]],null,[[\"position\"],[\"top\"]]]],[1,1,0,0,\"\\n\"],[1,0,0,0,[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"],[1,0,0,0,[27,[26,5,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"loading\",\"loading-slider\",\"notification-container\",\"-outlet\",\"component\",\"x-footer\"]}",
    "meta": {
      "moduleName": "lh-web/templates/application.hbs"
    }
  });

  _exports.default = _default;
});