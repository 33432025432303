define("lh-web/controllers/fields/field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    content: Ember.computed('model.content', function () {
      if (this.get('model.content')) {
        return PrismicDOM.RichText.asHtml(this.get('model.content'));
      }
    })
  });

  _exports.default = _default;
});