define("lh-web/mixins/loading-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Mixin = Ember.Mixin,
      inject = Ember.inject,
      isPresent = Ember.isPresent;

  var _default = Mixin.create({
    loadingSlider: inject.service(),
    actions: {
      loading: function loading() {
        var loadingSliderService = this.get('loadingSlider');
        loadingSliderService.startLoading();

        if (isPresent(this.router)) {
          this.router.one('didTransition', function () {
            loadingSliderService.endLoading();
          });
        }

        if (this.get('bubbleLoadingSlider')) {
          return true;
        }
      },
      finished: function finished() {
        this.get('loadingSlider').endLoading();
      }
    }
  });

  _exports.default = _default;
});