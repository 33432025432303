define("lh-web/cldrs/is", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jslint eqeq: true*/
  var _default = [{
    "locale": "is",
    "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      var s = String(n).split("."),
          i = s[0],
          t0 = Number(s[0]) == n,
          i10 = i.slice(-1),
          i100 = i.slice(-2);
      if (ord) return "other";
      return t0 && i10 == 1 && i100 != 11 || !t0 ? "one" : "other";
    },
    "fields": {
      "year": {
        "displayName": "ár",
        "relative": {
          "0": "á þessu ári",
          "1": "á næsta ári",
          "-1": "á síðasta ári"
        },
        "relativeTime": {
          "future": {
            "one": "eftir {0} ár",
            "other": "eftir {0} ár"
          },
          "past": {
            "one": "fyrir {0} ári",
            "other": "fyrir {0} árum"
          }
        }
      },
      "year-short": {
        "displayName": "ár",
        "relative": {
          "0": "á þessu ári",
          "1": "á næsta ári",
          "-1": "á síðasta ári"
        },
        "relativeTime": {
          "future": {
            "one": "eftir {0} ár",
            "other": "eftir {0} ár"
          },
          "past": {
            "one": "fyrir {0} ári",
            "other": "fyrir {0} árum"
          }
        }
      },
      "month": {
        "displayName": "mánuður",
        "relative": {
          "0": "í þessum mánuði",
          "1": "í næsta mánuði",
          "-1": "í síðasta mánuði"
        },
        "relativeTime": {
          "future": {
            "one": "eftir {0} mánuð",
            "other": "eftir {0} mánuði"
          },
          "past": {
            "one": "fyrir {0} mánuði",
            "other": "fyrir {0} mánuðum"
          }
        }
      },
      "month-short": {
        "displayName": "mán.",
        "relative": {
          "0": "í þessum mán.",
          "1": "í næsta mán.",
          "-1": "í síðasta mán."
        },
        "relativeTime": {
          "future": {
            "one": "eftir {0} mán.",
            "other": "eftir {0} mán."
          },
          "past": {
            "one": "fyrir {0} mán.",
            "other": "fyrir {0} mán."
          }
        }
      },
      "day": {
        "displayName": "dagur",
        "relative": {
          "0": "í dag",
          "1": "á morgun",
          "2": "eftir tvo daga",
          "-2": "í fyrradag",
          "-1": "í gær"
        },
        "relativeTime": {
          "future": {
            "one": "eftir {0} dag",
            "other": "eftir {0} daga"
          },
          "past": {
            "one": "fyrir {0} degi",
            "other": "fyrir {0} dögum"
          }
        }
      },
      "day-short": {
        "displayName": "dagur",
        "relative": {
          "0": "í dag",
          "1": "á morgun",
          "2": "eftir tvo daga",
          "-2": "í fyrradag",
          "-1": "í gær"
        },
        "relativeTime": {
          "future": {
            "one": "eftir {0} dag",
            "other": "eftir {0} daga"
          },
          "past": {
            "one": "fyrir {0} degi",
            "other": "fyrir {0} dögum"
          }
        }
      },
      "hour": {
        "displayName": "klukkustund",
        "relative": {
          "0": "þessa stundina"
        },
        "relativeTime": {
          "future": {
            "one": "eftir {0} klukkustund",
            "other": "eftir {0} klukkustundir"
          },
          "past": {
            "one": "fyrir {0} klukkustund",
            "other": "fyrir {0} klukkustundum"
          }
        }
      },
      "hour-short": {
        "displayName": "klst.",
        "relative": {
          "0": "þessa stundina"
        },
        "relativeTime": {
          "future": {
            "one": "eftir {0} klst.",
            "other": "eftir {0} klst."
          },
          "past": {
            "one": "fyrir {0} klst.",
            "other": "fyrir {0} klst."
          }
        }
      },
      "minute": {
        "displayName": "mínúta",
        "relative": {
          "0": "á þessari mínútu"
        },
        "relativeTime": {
          "future": {
            "one": "eftir {0} mínútu",
            "other": "eftir {0} mínútur"
          },
          "past": {
            "one": "fyrir {0} mínútu",
            "other": "fyrir {0} mínútum"
          }
        }
      },
      "minute-short": {
        "displayName": "mín.",
        "relative": {
          "0": "á þessari mínútu"
        },
        "relativeTime": {
          "future": {
            "one": "eftir {0} mín.",
            "other": "eftir {0} mín."
          },
          "past": {
            "one": "fyrir {0} mín.",
            "other": "fyrir {0} mín."
          }
        }
      },
      "second": {
        "displayName": "sekúnda",
        "relative": {
          "0": "núna"
        },
        "relativeTime": {
          "future": {
            "one": "eftir {0} sekúndu",
            "other": "eftir {0} sekúndur"
          },
          "past": {
            "one": "fyrir {0} sekúndu",
            "other": "fyrir {0} sekúndum"
          }
        }
      },
      "second-short": {
        "displayName": "sek.",
        "relative": {
          "0": "núna"
        },
        "relativeTime": {
          "future": {
            "one": "eftir {0} sek.",
            "other": "eftir {0} sek."
          },
          "past": {
            "one": "fyrir {0} sek.",
            "other": "fyrir {0} sek."
          }
        }
      }
    },
    "numbers": {
      "decimal": {
        "long": [[1000, {
          "one": ["0 þúsund", 1],
          "other": ["0 þúsund", 1]
        }], [10000, {
          "one": ["00 þúsund", 2],
          "other": ["00 þúsund", 2]
        }], [100000, {
          "one": ["000 þúsund", 3],
          "other": ["000 þúsund", 3]
        }], [1000000, {
          "one": ["0 milljón", 1],
          "other": ["0 milljónir", 1]
        }], [10000000, {
          "one": ["00 milljón", 2],
          "other": ["00 milljónir", 2]
        }], [100000000, {
          "one": ["000 milljón", 3],
          "other": ["000 milljónir", 3]
        }], [1000000000, {
          "one": ["0 milljarður", 1],
          "other": ["0 milljarðar", 1]
        }], [10000000000, {
          "one": ["00 milljarður", 2],
          "other": ["00 milljarðar", 2]
        }], [100000000000, {
          "one": ["000 milljarður", 3],
          "other": ["000 milljarðar", 3]
        }], [1000000000000, {
          "one": ["0 billjón", 1],
          "other": ["0 billjónir", 1]
        }], [10000000000000, {
          "one": ["00 billjón", 2],
          "other": ["00 billjónir", 2]
        }], [100000000000000, {
          "one": ["000 billjón", 3],
          "other": ["000 billjónir", 3]
        }]],
        "short": [[1000, {
          "one": ["0 þ'.'", 1],
          "other": ["0 þ'.'", 1]
        }], [10000, {
          "one": ["00 þ'.'", 2],
          "other": ["00 þ'.'", 2]
        }], [100000, {
          "one": ["000 þ'.'", 3],
          "other": ["000 þ'.'", 3]
        }], [1000000, {
          "one": ["0 m'.'", 1],
          "other": ["0 m'.'", 1]
        }], [10000000, {
          "one": ["00 m'.'", 2],
          "other": ["00 m'.'", 2]
        }], [100000000, {
          "one": ["000 m'.'", 3],
          "other": ["000 m'.'", 3]
        }], [1000000000, {
          "one": ["0 ma'.'", 1],
          "other": ["0 ma'.'", 1]
        }], [10000000000, {
          "one": ["00 ma'.'", 2],
          "other": ["00 ma'.'", 2]
        }], [100000000000, {
          "one": ["000 ma'.'", 3],
          "other": ["000 ma'.'", 3]
        }], [1000000000000, {
          "one": ["0 bn", 1],
          "other": ["0 bn", 1]
        }], [10000000000000, {
          "one": ["00 bn", 2],
          "other": ["00 bn", 2]
        }], [100000000000000, {
          "one": ["000 bn", 3],
          "other": ["000 bn", 3]
        }]]
      }
    }
  }];
  _exports.default = _default;
});