define("lh-web/components/x-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['x-modal animated fadeIn delay-1s'],
    didInsertElement: function didInsertElement() {
      $('html, body').addClass('no-scroll');
      $('.x-modal').css('visibility', 'visible');
      this.set('modalClass', this.modalClass ? this.modalClass : "large-modal-container");
    },
    willDestroyElement: function willDestroyElement() {
      $('html, body').removeClass('no-scroll');
      Ember.run.cancel(this.later);
    },
    actions: {
      toggleModal: function toggleModal() {
        var _this = this;

        $('.x-modal').removeClass('fadeIn delay-1s').addClass('fadeOut faster');
        this.later = Ember.run.later(this, function () {
          _this.toggleProperty('toggleModal');
        }, 550);
      }
    }
  });

  _exports.default = _default;
});