define("lh-web/templates/components/job-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r4ZnkPKA",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[9,\"h2\",true],[12,\"class\",\"center\",null],[10],[1,0,0,0,[27,[26,3,\"AppendSingleId\"],[]]],[11]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[9,\"div\",true],[12,\"class\",\"fields-container\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,7,\"BlockHead\"],[]],[[31,0,0,[27,[26,6,\"CallHead\"],[]],[[31,0,0,[27,[26,6,\"CallHead\"],[]],[[27,[26,5,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[1,0,0,0,[31,123,9,[27,[26,2,\"CallHead\"],[]],null,[[\"title\",\"description\",\"modelId\"],[[27,[24,1],[\"title\"]],[27,[24,1],[\"description\"]],[27,[24,1],[\"id\"]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[27,[26,8,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[9,\"div\",true],[12,\"class\",\"fields-btn-container\",null],[10],[1,1,0,0,\"\\n  \"],[5,[27,[26,1,\"BlockHead\"],[]],null,[[\"tagName\",\"class\",\"route\"],[\"button\",\"btn\",\"fields\"]],[[\"default\"],[{\"statements\":[[1,0,0,0,[31,326,1,[27,[26,0,\"CallHead\"],[]],[\"Skoða fleiri starfssvið\"],null]]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\",\"job-field\",\"title\",\"if\",\"fields\",\"-track-array\",\"each\",\"showMore\"]}",
    "meta": {
      "moduleName": "lh-web/templates/components/job-fields.hbs"
    }
  });

  _exports.default = _default;
});