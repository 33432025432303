define("lh-web/adapters/application", ["exports", "ember-data", "lh-web/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTURL = _environment.default.RESTURL,
      NAMESPACE = _environment.default.NAMESPACE;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    hostname: RESTURL,
    namespace: NAMESPACE,
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json, text/javascript, */*; q=0.01"
    }
  });

  _exports.default = _default;
});