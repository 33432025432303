define("lh-web/components/x-hero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      $ = _Ember.$;

  var _default = Ember.Component.extend({
    classNames: ['hero'],
    mobileUtils: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.set('isIE11', !!(navigator.userAgent.match(/Trident/) && navigator.userAgent.match(/rv[ :]11/)));

      if (this.get('mobileUtils').isMobile()) {
        $('#hero').height(window.innerHeight);
      }

      if (this.get('mobileUtils').getUA() == 'ios') {
        $('#menu').height(window.innerHeight);
      }

      $(window).on('scroll', function () {
        $('#scroll-indicator').fadeOut();
        var scrollPercent = 100 * $(window).scrollTop() / ($(document).height() - $(window).height());
        var stickyHeight = $('.centered_content').height();
        var marginTop = 1 * scrollPercent;
        $('.centered_content').css('top', $('.hero__content').height() / 2 + marginTop);
      });
    },
    toggleMenu: function toggleMenu() {
      this.toggleProperty('mobileMenu');

      if (!this.get('mobileMenu')) {
        setTimeout(function () {
          $('#menu').removeClass('animated show slideOutLeft');
        }, 500);
        $('#menu').removeClass('slideInLeft').addClass('slideOutLeft');
        $('html, body').removeClass('no-scroll');
      } else {
        $('#menu').addClass('animated show slideInLeft');
        $('html, body').addClass('no-scroll');
        window.scrollTo(0, 0);
      }
    },
    willDestroyElement: function willDestroyElement() {
      $(window).off('scroll');
    }
  });

  _exports.default = _default;
});