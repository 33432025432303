define("lh-web/translations/is", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Ertu með spurningar? Hafðu samband!": "Ertu með spurningar? Hafðu samband!",
    "Ferill": "Ferill",
    "Fyrirtækið": "Fyrirtækið",
    "Gjaldskrá": "Gjaldskrá",
    "Hafa samband": "Hafa samband",
    "Heim": "Heim",
    "Helstu starfssvið": "Helstu starfssvið",
    "Hröð og árangursrík afgreiðsla og <br />sérhæfing til að fullnægja sívaxandi kröfum": "Hröð og árangursrík afgreiðsla og <br />sérhæfing til að fullnægja sívaxandi kröfum",
    "Hvernig getum við aðstoðað?": "Hvernig getum við aðstoðað?",
    "Menntun": "Menntun",
    "Nafn": "Nafn",
    "Netfang": "Netfang",
    "Reikningsupplýsingar": "Reikningsupplýsingar",
    "Sagan": "Sagan",
    "Senda": "Senda",
    "Sendu starfsmanni skilaboð": "Sendu starfsmanni skilaboð",
    "Skilaboð": "Skilaboð",
    "Skilmálar": "Skilmálar",
    "Skoða fleiri starfssvið": "Skoða fleiri starfssvið",
    "Skrifaðu skilaboð til okkar og við svörum eins fljótt og auðið er.": "Skrifaðu skilaboð til okkar og við svörum eins fljótt og auðið er.",
    "Skrunaðu": "Skrunaðu",
    "Starfsfólk": "Starfsfólk",
    "Starfsfólk MAGNA Lögmanna": "Starfsfólk MAGNA Lögmanna",
    "Starfssvið": "Starfssvið",
    "Símanúmer": "Símanúmer",
    "Upplýsingar": "Upplýsingar",
    "Við veitum alhliða<br />lögfræðiþjónustu": "Við veitum alhliða<br />lögfræðiþjónustu",
    "kt.": "kt.",
    "starfsmanninn": "starfsmanninn",
    "um": "um",
    "Úrræði vegna lögmannskostnaðar": "Úrræði vegna lögmannskostnaðar"
  };
  _exports.default = _default;
});