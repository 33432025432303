define("lh-web/templates/components/x-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ecieGYOJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"overlay\",null],[10],[11],[1,1,0,0,\"\\n\"],[9,\"div\",true],[13,\"class\",[32,[[27,[26,1,\"AppendSingleId\"],[]]]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"div\",true],[12,\"class\",\"x-modal-header\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"h1\",true],[10],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"  \"],[9,\"div\",true],[12,\"class\",\"modal-close\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"button\",false],[23,\"class\",\"btn-close\",null],[3,0,0,[27,[26,3,\"ModifierHead\"],[]],[[27,[24,0],[]],\"toggleModal\"],null],[10],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"x-modal-content\",null],[10],[1,1,0,0,\"\\n    \"],[16,1,null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"modalClass\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "lh-web/templates/components/x-modal.hbs"
    }
  });

  _exports.default = _default;
});