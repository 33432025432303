define("lh-web/templates/components/company-subpage-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2vDKgBDg",
    "block": "{\"symbols\":[],\"statements\":[[9,\"ul\",true],[10],[1,1,0,0,\"\\n  \"],[9,\"li\",false],[14,\"class\",[32,[[31,53,2,[27,[26,2,\"CallHead\"],[]],[[31,57,2,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]],\"history\"],null],\"active\"],null]]],null],[3,0,0,[27,[26,3,\"ModifierHead\"],[]],[[27,[24,0],[]],\"setFilter\",\"history\"],null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,97,1,[27,[26,4,\"CallHead\"],[]],[\"Sagan\"],null]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"filter\",\"eq\",\"if\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "lh-web/templates/components/company-subpage-filters.hbs"
    }
  });

  _exports.default = _default;
});