define("lh-web/templates/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x5meeHW7",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[9,\"div\",true],[12,\"class\",\"content\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"nav-margin\",null],[10],[1,1,0,0,\"\\n   \"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n     \"],[9,\"div\",true],[12,\"class\",\"col-lg-14 right\",null],[10],[1,1,0,0,\"\\n       \"],[1,0,0,0,[31,124,12,[27,[26,2,\"CallHead\"],[]],null,[[\"padding\",\"class\",\"mainTitle\"],[true,\"extra-margin\",[31,182,1,[27,[26,1,\"CallHead\"],[]],[\"Hafa samband\"],null]]]]],[1,1,0,0,\"\\n     \"],[11],[1,1,0,0,\"\\n     \"],[9,\"div\",true],[12,\"class\",\"col-lg-10 map left\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"div\",true],[12,\"class\",\"mapouter\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"div\",true],[12,\"class\",\"gmap_canvas\",null],[10],[1,1,0,0,\"\\n          \"],[9,\"iframe\",true],[12,\"id\",\"gmap_canvas\",null],[12,\"src\",\"https://maps.google.com/maps?q=H%C3%B6f%C3%B0abaki%209&t=&z=13&ie=UTF8&iwloc=&output=embed\",null],[12,\"frameborder\",\"0\",null],[12,\"scrolling\",\"no\",null],[12,\"marginheight\",\"0\",null],[12,\"marginwidth\",\"0\",null],[10],[11],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n   \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"x-nav\",\"t\",\"contact-form\"]}",
    "meta": {
      "moduleName": "lh-web/templates/contact.hbs"
    }
  });

  _exports.default = _default;
});