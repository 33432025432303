define("lh-web/services/email-service", ["exports", "lh-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMAIL = _environment.default.EMAIL,
      EMAILURL = _environment.default.EMAILURL;

  var _default = Ember.Service.extend({
    request: function request(data, url) {
      var headers = {
        "Accept": "application/json, text/javascript, */*; q=0.01",
        "Content-Type": "application/json"
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var jqxhr = Ember.$.ajax({
          type: 'POST',
          url: url,
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify(data),
          headers: headers
        }).fail(function () {
          reject(jqxhr.responseJSON);
        }).done(function () {
          resolve(jqxhr.responseJSON);
        });
      });
    },
    sendEmail: function sendEmail(name, email, to, toName, msisdn, content) {
      var data = {
        "receiverEmail": to,
        "receiverName": toName,
        "from": name,
        "phone": msisdn,
        "senderEmail": email,
        "messageContent": content
      };
      return this.request(data, EMAILURL).then(function (response) {
        return response;
      });
    }
  });

  _exports.default = _default;
});