define("lh-web/components/job-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNames: ['job-field'],
    click: function click() {
      this.get('router').transitionTo('fields.field', this.get('modelId'));
    }
  });

  _exports.default = _default;
});