define("lh-web/templates/employees/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sxR8S8BJ",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[9,\"div\",true],[12,\"class\",\"content\",null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,36,12,[27,[26,3,\"CallHead\"],[]],null,[[\"class\",\"mainEmployees\",\"title\",\"description\"],[\"nav-margin\",true,[31,94,1,[27,[26,2,\"CallHead\"],[]],[\"Starfsfólk\"],null],[27,[26,1,\"Expression\"],[\"content\"]]]]]],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,141,11,[27,[26,5,\"CallHead\"],[]],null,[[\"employees\"],[[27,[26,4,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"x-nav\",\"page\",\"t\",\"highlight-bg\",\"employees\",\"x-employees\"]}",
    "meta": {
      "moduleName": "lh-web/templates/employees/index.hbs"
    }
  });

  _exports.default = _default;
});