define("lh-web/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Ertu með spurningar? Hafðu samband!": "Any questions? Contact us!",
    "Ferill": "Curriculum",
    "Fyrirtækið": "The company",
    "Gjaldskrá": "Pricing",
    "Hafa samband": "Contact us",
    "Heim": "Home",
    "Helstu starfssvið": "Main practice areas",
    "Hröð og árangursrík afgreiðsla og <br />sérhæfing til að fullnægja sívaxandi kröfum": "Fast and sucessful legal services and <br />specialization to meet ever-growing demands",
    "Hvernig getum við aðstoðað?": "How can we assist?",
    "Menntun": "Education",
    "Nafn": "Your name",
    "Netfang": "Email address",
    "Reikningsupplýsingar": "Bank information",
    "Sagan": "The history",
    "Senda": "Send",
    "Sendu starfsmanni skilaboð": "Send this employee a message",
    "Skilaboð": "Message",
    "Skilmálar": "Terms",
    "Skoða fleiri starfssvið": "View more practice areas",
    "Skrifaðu skilaboð til okkar og við svörum eins fljótt og auðið er.": "Your message here...",
    "Skrunaðu": "Scroll down",
    "Starfsfólk": "The team",
    "Starfsfólk MAGNA Lögmanna": "Our Team",
    "Starfssvið": "Practice areas",
    "Símanúmer": "Phone number",
    "Upplýsingar": "Info",
    "Við veitum alhliða<br />lögfræðiþjónustu": "MAGNA provides<br /> versatile legal services",
    "kt.": "Id no.",
    "starfsmanninn": "the employee",
    "um": "about",
    "Úrræði vegna lögmannskostnaðar": "Solutions regarding legal fees"
  };
  _exports.default = _default;
});