define("lh-web/templates/components/number-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TeigClY6",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[31,2,5,[27,[26,8,\"CallHead\"],[]],null,[[\"type\",\"id\",\"minlength\",\"maxlength\",\"value\",\"placeholder\",\"keyDown\",\"class\",\"disabled\"],[\"text\",[27,[26,7,\"Expression\"],[]],[27,[26,6,\"Expression\"],[]],[27,[26,5,\"Expression\"],[]],[27,[26,4,\"Expression\"],[]],[27,[26,3,\"Expression\"],[]],[31,111,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"handleKeyDown\"],null],[27,[26,1,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"className\",\"action\",\"placeholder\",\"value\",\"maxlength\",\"minlength\",\"id\",\"input\"]}",
    "meta": {
      "moduleName": "lh-web/templates/components/number-input.hbs"
    }
  });

  _exports.default = _default;
});