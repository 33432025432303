define("lh-web/controllers/information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    filter: 'bank',
    description: Ember.computed('model.data.content', function () {
      return this.get('model.data.content.firstObject.text');
    }),
    solutions: Ember.computed('model.data.solutions', function () {
      return PrismicDOM.RichText.asHtml(this.get('model.data.solutions'));
    }),
    tariffs: Ember.computed('model.data.tariff', function () {
      return PrismicDOM.RichText.asHtml(this.get('model.data.tariff'));
    }),
    terms: Ember.computed('model.data.terms', function () {
      return PrismicDOM.RichText.asHtml(this.get('model.data.terms'));
    })
  });

  _exports.default = _default;
});