define("lh-web/helpers/as-html", ["exports", "ember-cli-prismic/helpers/as-html"], function (_exports, _asHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _asHtml.default;
    }
  });
  Object.defineProperty(_exports, "asHtml", {
    enumerable: true,
    get: function get() {
      return _asHtml.asHtml;
    }
  });
});