define("lh-web/components/highlight-bg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['highlight-bg'],
    classNameBindings: ['mainJobField:jobs-bg', 'mainEmployees:employees-bg']
  });

  _exports.default = _default;
});