define("lh-web/templates/components/language-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WVyIk8+t",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"language-menu\",null],[10],[1,1,0,0,\"\\n  \\n  \"],[9,\"button\",false],[23,\"class\",\"btn btn-lang\",null],[23,\"title\",\"\",null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[\"click\",[31,85,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"setLanguage\"]]],null]],null],[10],[1,1,0,0,\"\\n    \"],[9,\"span\",true],[10],[1,0,0,0,[27,[24,0],[\"getLanguage\"]]],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "lh-web/templates/components/language-menu.hbs"
    }
  });

  _exports.default = _default;
});