define("lh-web/routes/fields/field", ["exports", "lh-web/routes/current-user-route"], function (_exports, _currentUserRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _currentUserRoute.default.extend({
    prismic: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      return this.get('prismic').getApi().then(function (api) {
        return api.getByUID('field', params.id, {
          'fetchLinks': ['employee.name', 'employee.email', 'employee.photo', 'employee.occupation'],
          lang: _this.get('currentUser.language')
        });
      });
    },
    setupController: function setupController(controller, model) {
      if (!model) {
        return;
      }

      controller.set('model', this.get('dataFormatter').formatField(model));
    },
    actions: {
      didTransition: function didTransition() {
        window.scrollTo(0, 0);
      }
    }
  });

  _exports.default = _default;
});