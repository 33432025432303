define("lh-web/models/employee", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    name: attr('string'),
    email: attr('string'),
    description: attr('string'),
    order: attr('number'),
    show: attr('boolean')
  });

  _exports.default = _default;
});