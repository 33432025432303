define("lh-web/services/prismic", ["exports", "ember-cli-prismic/services/prismic"], function (_exports, _prismic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _prismic.default;
    }
  });
});