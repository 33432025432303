define("lh-web/services/data-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    formatFields: function formatFields(results) {
      var fields = [];
      results.map(function (result) {
        fields.push({
          id: result.uid,
          title: result.data && result.data.title.length ? result.data.title[0].text : null,
          description: result.data && result.data.description.length ? result.data.description[0].text : null,
          content: result.data && result.data.content.length ? result.data.content[0].text : null
        });
      });
      return fields;
    },
    formatEmployees: function formatEmployees(results) {
      var _this = this;

      var employees = [];
      results.map(function (result) {
        employees.push({
          id: result.uid,
          name: result.data && result.data.name.length ? result.data.name[0].text : null,
          initials: _this.generateEmployeeInitials("".concat(result.data && result.data.name.length ? result.data.name[0].text : null)),
          occupation: result.data && result.data.occupation.length ? result.data.occupation[0].text : null,
          photo: result.data && result.data.photo ? result.data.photo.url : null,
          order: result.data && result.data.order ? result.data.order : 999999
        });
      });
      return employees;
    },
    formatField: function formatField(field) {
      return {
        id: field ? field.uid : null,
        title: field.data && field.data.title.length ? field.data.title[0].text : null,
        description: field.data && field.data.description.length ? field.data.description[0].text : null,
        content: field.data ? field.data.content : null,
        featuredImage: field.data && field.data["featured-image"] ? field.data["featured-image"].url : null,
        employees: field.data ? field.data.employees : null
      };
    },
    formatEmployee: function formatEmployee(employee) {
      var fields = [];
      employee.data.fields.map(function (data) {
        if (data.field && data.field.data) {
          fields.push({
            id: data.field ? data.field.uid : null,
            title: data.field && data.field.data && data.field.data.title.length ? data.field.data.title[0].text : null,
            description: data.field && data.field.data && data.field.data.description.length ? data.field.data.description[0].text : null
          });
        }
      });
      return {
        id: employee ? employee.id : null,
        name: employee.data && employee.data.name.length ? employee.data.name[0].text : null,
        occupation: employee.data && employee.data.occupation.length ? employee.data.occupation[0].text : null,
        photo: employee.data && employee.data.photo ? employee.data.photo.url : null,
        order: employee.data && employee.data.order ? employee.data.order : 0,
        about: employee.data ? employee.data.about : null,
        education: employee.data ? employee.data.education : [],
        caseRecords: employee.data && employee.data.records.length ? employee.data.records : [],
        email: employee.data && employee.data.email && employee.data.email.length ? employee.data.email[0].text : null,
        show: employee.data && employee.data.show ? employee.data.show : false,
        fields: fields,
        initials: this.generateEmployeeInitials("".concat(employee.data && employee.data.name.length ? employee.data.name[0].text : null))
      };
    },
    formatPage: function formatPage(results) {
      return {
        content: results.length ? results[0].data.content[0] ? results[0].data.content[0].text : null : null
      };
    },
    generateEmployeeInitials: function generateEmployeeInitials(name) {
      var temp = "";

      if (name) {
        var words = name.split(' ');

        for (var i = 0; i < words.length; ++i) {
          temp += words[i].charAt(0);
        }
      }

      return temp;
    }
  });

  _exports.default = _default;
});