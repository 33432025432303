define("lh-web/components/information-subpage-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['subpage-filters'],
    actions: {
      setFilter: function setFilter(filter) {
        this.set('filter', filter);
      }
    }
  });

  _exports.default = _default;
});