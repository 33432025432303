define("lh-web/templates/components/highlight-bg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TwoucJMd",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"container\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[12,\"class\",\"bread-crumbs\",null],[10],[1,1,0,0,\"\\n     \"],[5,[27,[26,1,\"BlockHead\"],[]],null,[[\"route\"],[\"fields\"]],[[\"default\"],[{\"statements\":[[1,0,0,0,[31,106,1,[27,[26,0,\"CallHead\"],[]],[\"Starfssvið\"],null]]],\"parameters\":[]}]]],[1,1,0,0,\" / \"],[9,\"strong\",true],[10],[1,0,0,0,[27,[26,2,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"  \"],[9,\"div\",true],[12,\"class\",\"info\",null],[10],[1,1,0,0,\"\\n\"],[1,1,0,0,\"    \"],[9,\"p\",true],[12,\"class\",\"light\",null],[10],[1,1,0,0,\"\\n      \"],[1,0,0,0,[27,[26,5,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\",\"title\",\"hasBreadCrumbs\",\"if\",\"description\"]}",
    "meta": {
      "moduleName": "lh-web/templates/components/highlight-bg.hbs"
    }
  });

  _exports.default = _default;
});